import { COOKIE } from '@iva/utils';
import { markerForceCookie } from '@iva/marker';

const markerCookie = 'udid';

/**
 * List of rtgIds where udid should not be generated
 */
export const BLACKLIST = [1333333];

export const isUdidBlacklisted = (rtgId) => BLACKLIST.includes(rtgId);

/**
 * Handles udid generation
 * @param {number} rtgId retargeting ID
 * @param {number} consent user consent
 */
export const handleUdid = async (consent, rtgId) => {
  if (rtgId && isUdidBlacklisted(rtgId) && !COOKIE.getCookie(markerCookie)) {
    window.sznIVA.IS.clearIdentities(['udid']);
    return;
  }

  if (consent === 1 || consent === '1') {
    // already has consent from config, force creating mark
    await markerForceCookie();
  }
};
