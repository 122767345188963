import { rcDebug } from './debug';
import { compatRetargetingHit } from './retargetingHit';
import { compatConversionHit } from './conversionHit';
import { SID_STORE_CHECK } from '@sklik/cmp2-common';
import { getConsent, setConsent } from './consentManager';

let waitingForDependencies = true;

/**
 * Finish waiting
 */
export const finishWaitingForDependencies = () => {
  waitingForDependencies = false;
};

/**
 * Checks if still waiting
 */
export const isWaitingForDependencies = () => waitingForDependencies;

// retargetingHit queue
const queue = [];

/**
 * Adds retargetingHit call to queue
 */
export const addHitToQueue = (func, conf) => {
  queue.push({ func, conf });
};

/**
 * Gets hit queue
 * @returns {Array} queue
 */
export const getHitQueue = () => queue;

/**
 * Processes all calls in queue
 */
export const processHitQueue = () => {
  if (queue.length > 0) {
    rcDebug({ message: `Starts processing queue containing ${queue.length} Hit(s):` });
  }

  while (queue.length > 0) {
    const { func, conf } = queue.shift();
    if (conf !== null && typeof conf === 'object' && getConsent() === 1) {
      // update consent in conf object
      conf.consent = getConsent();
    }
    // call the function
    func(conf, rcDebug);
  }
};

export const handleCompatHit = async (conversion = true) => {
  rcDebug({ message: 'Compat Hits initialization started, verifying consent' });

  const doCompatHit = async () => {
    finishWaitingForDependencies();
    // retargetingHit
    await compatRetargetingHit().catch((err) => {
      rcDebug({ type: 'error', message: 'Compat Retargeting Hit failed.', err });
    });
    if (conversion) {
      await compatConversionHit();
    }
  };

  if (window.rc && [0, 1].includes(window.rc.consent)) {
    await doCompatHit();
  } else {
    SID_STORE_CHECK.sidLegalStoreCheckPromise(5000)
      .then((allowed) => {
        const allowedInt = allowed ? 1 : 0;
        if (allowedInt) {
          // consent with purposeOne is present
          rcDebug({ message: `Found consent with purposeOne, setting window.rc.consent = ${allowedInt}` });
          setConsent(allowedInt);
        }
      })
      .catch((err) => {
        rcDebug({ type: 'error', message: 'Legal store check (consent or UDID) failed.', err });
      })
      .finally(async () => {
        rcDebug({ message: 'Consent verification done.' });
        await doCompatHit();

        // process all window.rc.retargetingHit calls
        processHitQueue();
      });
  }
};
