import { rcDebug } from './debug';
import { conversionHitZbozi } from './zbozi';

import { CONVERSION_BASE_URL, COOKIE_NAMES, CONVERSION_HIT_ID_LENGTH } from './constants';
import { getConsent, getVerifiedConsentValue, setConsent } from './consentManager';
import { isWaitingForDependencies, addHitToQueue } from '../modules/compatHit';
import { handleUdid } from '../modules/udid';
import { getRandomString } from './utils/getRandomString';

import { getIdentityObject } from './utils/getIdentityObject';
import { getConversionDataSklik, getConversionDataZbozi } from './utils/getConversionData';

import { HitSender } from './HitSender/HitSender';

import { SID_UTILS, COOKIE } from '@iva/utils';
import { CONSENT } from '@sklik/cmp2-common';

// conversion Hit Sklik
export const conversionHitSklik = async (conf, debug = rcDebug) => {
  if (!conf?.id || !conf?.conversionHitId) {
    debug(
      'error',
      'ConversionHit has not been sent to sklik server. No required fields "conf.id" or "conf.conversionHitId".'
    );
    return;
  }

  const { internal } = window.rc || {};

  let verifiedConsent = getVerifiedConsentValue(conf.consent ?? getConsent());
  const euconsentV2 = internal && CONSENT.getConsentFromCookieList()?.consentString;
  if (verifiedConsent === -1 && euconsentV2) {
    verifiedConsent = CONSENT.hasPurposeOne(euconsentV2) ? 1 : -1;
  }

  // sync with window.rc.consent
  setConsent(verifiedConsent);

  if (verifiedConsent !== -1) {
    await handleUdid(conf.consent);
  } else if (isWaitingForDependencies()) {
    addHitToQueue(window.rc.conversionHit, conf);
    debug({ message: 'RetargetingHit is queued, consent verification not done yet.' });
    return;
  }

  debug({ message: 'Preparing ConversionHit...' });

  const { id, value, orderId, conversionHitId } = conf;

  const consentValue = internal ? null : verifiedConsent;
  const legallyOk = verifiedConsent || internal;

  const sidCookie = COOKIE.getCookie(COOKIE_NAMES.SID);
  const sid = legallyOk && SID_UTILS.isValidSid(sidCookie) ? sidCookie : '';

  const sznaiidCookie = COOKIE.getCookie(COOKIE_NAMES.SZNAIID);
  const sznaiid = legallyOk ? sznaiidCookie : '';

  const ids = getIdentityObject();

  debug({ message: 'got following conversion data:', id, value, orderId, sid, euconsentV2 });

  new HitSender(CONVERSION_BASE_URL, debug)
    .appendValue('id', id)
    .appendValue('value', value)
    .appendValue('orderId', orderId)
    .appendValue('url', window.location.href)
    .appendValue('dsid', sid)
    .appendValue('sznaiid', sznaiid)
    .appendValue('euconsent', euconsentV2)
    .appendValue('consent', consentValue)
    .appendValue('conversionHitId', conversionHitId)
    .appendObject('ids', ids)
    .send();
};

/**
 * Sends conversion hit with provided data.
 * @param {Object} conf Data for the conversion.
 * @param {Number} [conf.id] Conversion ID, Required field for SKLIK, Example = 123
 * @param {Number} [conf.value] Order price, Example = 199
 * @param {String} [conf.orderId] OrderId, Example = 'DN1231882C'
 * @param {String} [conf.zboziType] Type of zbozi message,
 * Example = 'standard' (standard conversion tracking),
 *           'limited' (limited tracking),
 *           'sandbox' (standard tracking test mode)
 * @param {Number} [conf.zboziId] Eshop ID, Required field for ZBOZI, Example = 123
 * @param {Number} [conf.consent] Consent from the visitor to submit a retargeting hit, Example = 0 (není souhlas) nebo 1 (je souhlas)
 */
export const conversionHit = async (conf, debug = rcDebug) => {
  // conversionHitId {String[75]} Unique conversion ID for sklik and zbozi
  if (!conf.conversionHitId) {
    conf.conversionHitId = getRandomString(CONVERSION_HIT_ID_LENGTH);
  }
  await conversionHitSklik(conf, debug);
  conversionHitZbozi(conf);
};

export const compatConversionHit = async () => {
  // conversionHitId {String[75]} Unique conversion ID for sklik and zbozi
  const conversionHitId = getRandomString(CONVERSION_HIT_ID_LENGTH);

  // SKLIK conversion
  const conversionConfSklik = getConversionDataSklik();
  if (conversionConfSklik) {
    rcDebug({ message: 'Conversion got this data (sklik-conversion):', ...conversionConfSklik });
    if (Array.isArray(conversionConfSklik)) {
      await Promise.allSettled(
        conversionConfSklik.map((conversionConfSklikFromArray) => {
          return conversionHitSklik({ ...conversionConfSklikFromArray, conversionHitId });
        })
      ).catch((err) => {
        rcDebug({ type: 'error', message: 'Compat Conversion Hit failed.', err });
      });
    } else {
      await conversionHitSklik({ ...conversionConfSklik, conversionHitId }).catch((err) => {
        rcDebug({ type: 'error', message: 'Compat Conversion Hit failed.', err });
      });
    }
  }

  // ZBOZI conversion
  const conversionConfZbozi = getConversionDataZbozi();
  rcDebug({ message: 'Conversion got this data (zbozi-conversion):', ...conversionConfZbozi });
  conversionHitZbozi({ ...conversionConfZbozi, conversionHitId });
};
