import { getRetargetingUrl } from './utils/getRetargetingUrl';
import { getRetargetingData } from './utils/getRetargetingData';
import { rcDebug } from './debug';

import { RETARGETING_BASE_URL, COOKIE_NAMES } from './constants';
import { getConsent, getVerifiedConsentValue, setConsent } from './consentManager';
import { isWaitingForDependencies, addHitToQueue } from '../modules/compatHit';
import { handleUdid } from '../modules/udid';
import { getIdentityObject } from './utils/getIdentityObject';
import { HitSender } from './HitSender/HitSender';
import IS from '@iva/identity-storage';
import { SID_UTILS, COOKIE } from '@iva/utils';
import { CONSENT } from '@sklik/cmp2-common';

/**
 * Sends retargeting hit with provided data.
 * @param {Object} conf Data for the retargeting.
 * @param {Number} conf.rtgId Retargeting ID, Example = 123
 * @param {String} [conf.category] Eshop categories, Example="Zahrada | Stínící technika | Zahradní slunečníky"
 * @param {String} [conf.itemId]  Offer ID, Example = "shovel"
 * @param {String} [conf.pageType] Page type, Example = "eshop"
 * @param {String} [conf.rtgUrl] Current URL (must match window.location.href)
 * @param {String | String[]} [conf.productIds] Products ID, Uses internal services (partners do not send)
 * @param {Number} [conf.consent] Consent from the visitor to submit a retargeting hit, Example = 0 (není souhlas) nebo 1 (je souhlas)
 * @param {Number} [conf.said] Cookie rus id. Uses internal services (partners do not send). There is no way to set it in window, it can be passed only when calling the function retargetingHit
 * @param {String} [conf.eid] hashed email address
 *
 * @param {Function} debug Debugger function from the script calling this function.
 * @return {Boolean} Indicator (true - retargetingHit sent , false - retargetingHit not sent)
 */
export const retargetingHit = async (conf, debug = rcDebug) => {
  if (!conf || !conf.rtgId) {
    debug('error', 'RetargetingHit has not been sent. No required fields "conf" or "conf.rtgId".');
    return false;
  }

  const { internal } = window.rc || {};

  let verifiedConsent = getVerifiedConsentValue(conf.consent ?? getConsent());
  const euconsentV2 = internal && CONSENT.getConsentFromCookieList()?.consentString;
  if (verifiedConsent === -1 && euconsentV2) {
    verifiedConsent = CONSENT.hasPurposeOne(euconsentV2) ? 1 : -1;
  }

  // sync with window.rc.consent
  setConsent(verifiedConsent);

  if (verifiedConsent !== -1) {
    await handleUdid(conf.consent, conf.rtgId);
  } else if (isWaitingForDependencies()) {
    addHitToQueue(window.rc.retargetingHit, conf);
    debug({ message: 'RetargetingHit is queued, consent verification not done yet.' });
    return false;
  }

  debug({ message: 'Preparing RetargetingHit...' });

  const { rtgId, category, itemId, pageType, rtgUrl, productIds, said, eid } = conf;

  const legallyOk = verifiedConsent || internal;
  const sidCookie = COOKIE.getCookie(COOKIE_NAMES.SID);
  const sid = legallyOk && SID_UTILS.isValidSid(sidCookie) ? sidCookie : '';
  const sznaiidCookie = COOKIE.getCookie(COOKIE_NAMES.SZNAIID);
  const sznaiid = legallyOk ? sznaiidCookie : '';
  if (eid) {
    // adding email eid to IdentityObject
    IS.updateIdentities({ eid });
  }
  const ids = getIdentityObject();

  debug({
    message: 'got following retargeting data:',
    rtgId,
    category,
    itemId,
    pageType,
    rtgUrl,
    productIds,
    euconsentV2,
    sid,
    sznaiid,
    verifiedConsent,
    said,
    ids,
  });

  const consentValue = internal ? null : verifiedConsent;

  const getURL = () => {
    debug({ message: 'Generating retargeting URL', internal, category, rtgUrl });
    const finalRetURL = getRetargetingUrl(rtgUrl, category, internal);
    debug({ message: 'Final retargeting URL', finalRetURL });
    return finalRetURL;
  };

  new HitSender(RETARGETING_BASE_URL, debug)
    .appendValue('id', rtgId)
    .appendValue('category', category)
    .appendValue('itemId', itemId)
    .appendValue('url', getURL())
    .appendValue('pageType', pageType)
    .appendValue('euconsent', euconsentV2)
    .appendValue('dsid', sid)
    .appendValue('sznaiid', sznaiid)
    .appendArray('productIds', productIds)
    .appendValue('consent', consentValue)
    .appendValue('said', said)
    .appendObject('ids', ids)
    .send();

  debug({ message: 'RetargetingHit has been sent.' });
  return true;
};

export const compatRetargetingHit = async () => {
  const retargetingConf = getRetargetingData();
  rcDebug({ message: 'Retargeting got this data:', ...retargetingConf });
  if (await retargetingHit(retargetingConf)) {
    window.seznam_dispatchedRetargetingIds = window.seznam_dispatchedRetargetingIds || [];
    window.seznam_dispatchedRetargetingIds.push(retargetingConf.rtgId);
  }
};
