import IS from '@iva/identity-storage';

/**
 * Gets identity object including login, sid, udid and euconsent
 * @returns {Object | null} identityObject
 */
export const getIdentityObject = () => {
  const ids = IS.getIdentities(
    { cacheEnough: true, justSettled: false },
    { login: { justSettled: true, cacheEnough: false } },
    true
  );

  return IS.prepareIdsForBe(ids, { includeMetadata: true });
};
